import iconLogo from '../media/logos/logo192.png';

import Header from "../components/Header";
import Link from '../components/Link';

export default function SubscriptionTermsOfService(){

    return(
        <div className="bg-white">

            <Header />

            {/* Content */}
            <div className="mx-auto max-w-7xl px-4 lg:px-6 my-8 lg:border-2 lg:rounded-2xl">

                <img className="mx-auto w-32 lg:w-60" src={iconLogo} alt="WhichWeight Icon Logo" />

                <div className="text-center text-lg lg:text-2xl font-bold">Subscription Terms Of Service</div>
                <div className="text-center lg:text-xl italic">Last Modified March 1, 2024</div>

                <div className='my-4'>These Terms of Service ("Terms") govern your subscription and use of WhichWeight (the "Service") offered by WhichWeight, LLC ("we," "us," or "our"). By selecting "Agree" and subscribing to the Service, you ("Client" or "you") accept and agree to these Terms. Please read these Terms carefully.</div>

                <div className='my-4 lg:my-8 space-y-4'>

                    <div className='underline'><strong>1. Subscription Tiers and Fees</strong></div>
                    
                    <div className="space-y-4 px-4">
                        <div className='space-y-2'>
                            <div className="font-semibold">One-Time Fees:</div>
                            <ul className="list-disc list-inside ml-4">
                                <li>Onboarding Fee: $100</li>
                                <li>Optional White Labeling Fee: $200 (for customized dashboard and app)</li>
                            </ul>
                        </div>

                        <div className='space-y-2'>
                            <div className="font-semibold">Monthly Subscription Fees Based on Number of Athletes:</div>
                            <ul className="list-disc list-inside ml-4">
                                <li>0-9 athletes: $39.99 per athlete per month</li>
                                <li>10-19 athletes: $37.99 per athlete per month</li>
                                <li>20-39 athletes: $34.99 per athlete per month</li>
                                <li>40+ athletes: $29.99 per athlete per month</li>
                            </ul>
                        </div>

                        <div className='space-y-2'>
                            <div className="font-semibold">Example Fees:</div>
                            <ul className="list-disc list-inside ml-4">
                                <li>With 20 athletes (first month): $700</li>
                                <li>With 20 athletes and White Label: $1,000</li>
                            </ul>
                        </div>
                    </div>


                    <div className='underline'><strong>2. Billing and Payment Terms</strong></div>

                    <div className="space-y-4 px-4">

                        <div><strong>Monthly Billing Cycle:</strong> Clients will be billed monthly through Stripe at the end of each month. Charges will reflect the total roster of active athletes within the month.</div>

                        <div><strong>Roster Slot Charges:</strong> If an athlete is added within the month, the charge for that slot will be included in the monthly bill. For example, if billing occurs on March 31 and an athlete was added on March 25, the charge for that athlete will be included in the March bill.</div>

                        <div><strong>Payment Method:</strong> A valid payment method (ACH or credit card) must be maintained on file. By agreeing to these Terms, you authorize us to charge your payment method on file for recurring subscription fees.</div>

                        <div><strong>Payment Processing Fees:</strong> The Client will absorb all payment processing and transaction fees imposed by Stripe.</div>

                    </div>

                    <div className='underline'><strong>3. Cancellation Policy</strong></div>
                    <div className='px-4'><strong>Athlete Account Cancellations: </strong> If an athlete requests a cancellation or pause, charges for that athlete will apply for the remainder of the billing month. The cancellation will take effect the following month.</div>
                    
                    <div className='underline'><strong>4. Elite Partnership Program</strong></div>
                    <div className='px-4'>Clients with at least 20 active athletes may opt into the Elite Partnership Program, benefiting from waived onboarding and white-labeling fees, along with a fixed per-athlete rate of $29.99 per month, regardless of the number of athletes.</div>

                    <div className='underline'><strong>5. Modifications to Pricing and Billing Terms</strong></div>
                    <div className='px-4'>We may adjust subscription fees, billing policies, or terms with a 30-day notice, delivered via email or within the Service. Continued use of the Service following the effective date of the revised terms constitutes acceptance of the new rates.</div>

                    <div className='underline'><strong>6. Billing Disputes</strong></div>
                    <div className='px-4'>For billing discrepancies, please contact us within 30 days of the charge date. We will investigate and resolve issues promptly, but are not responsible for disputes older than 30 days.</div>

                    <div className='underline'><strong>7. Compliance and Legal Disclosures</strong></div>
                    <div className='space-y-2 px-4'>
                        <div><strong>Compliance with Colorado and U.S. Laws:</strong> This Agreement complies with applicable SaaS billing regulations, including consumer rights and automatic renewal disclosure requirements.</div>
                        <div><strong>Electronic Signature:</strong> By clicking “Agree,” you acknowledge that this action constitutes an electronic signature under the E-Sign Act and Colorado law, confirming your acceptance of these Terms.</div>
                        <div><strong>Data Privacy:</strong> Your personal data is governed by our <a href='/privacy' className='underline text-blue-500 cursor-pointer'>Privacy Policy</a>. By agreeing to these Terms, you consent to our use of your data as outlined in that policy.</div>
                    </div>

                    <div className='underline'><strong>8. Contact Information</strong></div>
                    <div className='px-4'>If you have any questions about these Terms, please contact us at support@whichweight.com</div>

                </div>

            </div>

        </div>
    );

}